html {
  background-color: #f8f8f8;
}

.app {
  overflow-x: hidden !important;
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: 85vh;
  padding: 2rem;
}

.intro .myName {
  font-size: 2.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

.color-top {
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 0 0 15vh 100vw;
  border-color: transparent transparent #c75354 transparent;
  padding: 0 !important;
}

.color-bot {
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 15vh 100vw 0 0;
  border-color: #c75354 transparent transparent transparent;
  margin-bottom: 4rem;
  padding: 0 !important;
}

.colored {
  background-color: #c75354;
}

.no-margin {
  margin: 0 !important;
}

.colored-body {
  padding: 0 !important;
  background-color: #c75354;
}