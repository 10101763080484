

.descriptor {
  display: flex;
  flex-direction: row;
  cursor: default;
}

.descriptor-dash {
  margin-right: 0.2rem;
}