h1 {
  cursor: default;
}

.center {
  text-align: center;
}

.work-entry {
  margin-bottom: 3rem;
}

.work-location {
  font-size: 24px;
}

.work-pos {
  font-size: 20px;
  cursor: default;
}

.work-duration {
  color: #333333;
  padding-left: 0px;
  font-size: 20px;
}

.intro-cont {
  cursor: default;
  margin-top: 1rem;
}

.projects {
  background-color: #c75354;
  color: #f8f8f8;
  padding: 3rem 1rem 0rem 1rem;
}

.section-cont {
  margin-top: 3rem;
}

.icon-cntr {
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.intro svg {
  -webkit-transition: width 0.2s, height 0.2s; /* For Safari 3.1 to 6.0 */
  transition: width 0.2s, height 0.2s;
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  cursor: pointer;
}

.intro svg:hover {
  height: 3.5rem;
  width: 3.5rem;
}

.proj-entry {
  margin-bottom: 4rem;
}

.proj-vital {
  border-right: 2px solid #f8f8f8;
  cursor: default;
}

.proj-vital .title {
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title a {
  display: flex;
}

.title svg {
  margin-left: 0.6rem;
  cursor: pointer;
  height: 28px;
  width: 28px;
  color: #f8f8f8;
}

.proj-desc {
  padding-left: 2rem;
}

.proj-desc .blurb {
  cursor: default;
  font-size: 20px;
}

.pos-format {
  text-align: right;
  cursor: default;
}

.footer {
  cursor: default;
  margin-top: 8rem;
  margin-bottom: 4rem;
}

.footer h3 {
  font-size: 2.5rem;
}

.footer h3 svg {
  height: 2.6rem;
  width: 2.6rem;
}

.footer svg {
  height: 2rem;
  width: 2rem;
  margin-left: 0.3rem;
}

.footer .heart-format {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.heart-format svg {
  margin-left: 1rem;
}

.proj-tags {
  margin-top: 0.2rem;
}

@media (max-width: 576px) { 
  .proj-vital {
    border-right: none;
    margin-bottom: 1rem;
  }

  .proj-desc {
    padding-left: 1rem;
  }

  .proj-desc .blurb {
    font-size: 16px;
  }

  .work-desc {
    order: 2;
  }

  .pos-format {
    text-align: left;
  }

}